import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
  },
  {
    text: "Examples",
    url: "https://github.com/gatsbyjs/gatsby/tree/master/examples",
    description:
      "A collection of websites ranging from very basic to complex/complete that illustrate how to accomplish specific tasks within your Gatsby sites.",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Learn how to add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
  },
]

const samplePageLinks = [
  {
    text: "Page 2",
    url: "page-2",
    badge: false,
    description:
      "A simple example of linking to another page within a Gatsby site",
  },
  { text: "TypeScript", url: "using-typescript" },
  { text: "Server Side Rendering", url: "using-ssr" },
  { text: "Deferred Static Generation", url: "using-dsg" },
]

const moreLinks = [
  { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
  {
    text: "Documentation",
    url: "https://gatsbyjs.com/docs/",
  },
  {
    text: "Starters",
    url: "https://gatsbyjs.com/starters/",
  },
  {
    text: "Showcase",
    url: "https://gatsbyjs.com/showcase/",
  },
  {
    text: "Contributing",
    url: "https://www.gatsbyjs.com/contributing/",
  },
  { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => {
  React.useEffect(() => {
    ;(function (h, b, s, n, i, p, e, t) {
      h._HB_ = h._HB_ || {}
      h._HB_.pid = i
      t = b.createElement(s)
      t.type = "text/javascript"
      t.async = !0
      t.src = n
      e = b.getElementsByTagName(s)[0]
      e.parentNode.insertBefore(t, e)
    })(
      window,
      document,
      "script",
      "https://widget.honeybook.com/assets_users_production/websiteplacements/placement-controller.min.js",
      "632d4aa56b7d600008b6da1a"
    )
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <div className={styles.bgImage}></div>
      <div className={styles.earlyBird}>
        <a href="#contactUs">Ask us</a> about our early bird special pricing
        available for weddings in 2023!
      </div>
      <div className={styles.introContent}>
        <div className={styles.logoWrap}>
          <StaticImage
            src="../images/casamor-houston-wedding-films-logo.png"
            width={300}
            loading="eager"
            alt="CasAmor Houston Wedding Films logo"
            style={{ margin: `0 auto`, maxWidth: "300px" }}
          />
        </div>

        <div className={styles.taglineWrap}>
          <StaticImage
            src="../images/casamor-tagline.png"
            width={600}
            loading="eager"
            alt="crafting cinematic stories of weddings and love since 2003"
            style={{ margin: `0 auto` }}
          />
        </div>
      </div>
      <div className={styles.introBoxWrap}>
        <div className={styles.introBox}>
          <h1 className={styles.hi}>Hi there, and um abraço!</h1>

          <span className={styles.abracoMeaning}>
            (warm Brazilian greeting with a hug)
          </span>
          <p className={styles.intro}>
            If you're here looking for wedding film production or photography
            services, first let us congratulate you on your engagement!! That's
            super exciting!! CasAmor (a Brazilian-influenced name combining the
            Portuguese words for 'house/wedding' and 'love') is a new{" "}
            <strong>
              wedding (and other lifestyle events) films and photography company
            </strong>{" "}
            in the <strong>Houston, TX</strong> area run by a team with{" "}
            <strong>10+ years of experience</strong> in the industry. The
            owners, Don and Juliane Helm, discovered their love in Brazil, a
            country known for romance, thus the influence on the company. We
            can't wait to meet you and learn your love/amor story so we can
            create a work of art that you and your family will love and treasure
            forever!
          </p>
          <div className={styles.ctaWrap}>
            <a className={styles.contactUs} href="#contactUs">
              <span>Book Us Today</span>
            </a>
            <span className={styles.freeQuote}>No Obligation Free Quote</span>
          </div>
        </div>
      </div>
      <div className={styles.bottomContentWrap}>
        <div className={styles.bottomContentWrapInner}>
          <div className={styles.bottomContent}>
            <div className={styles.bottomContentSection}>
              <h2>
                We're Don <span className={styles.donAnd}>and</span>{" "}
                Juliane&nbsp;Helm
              </h2>
              <div className={styles.wrapperForMaxWidthElements}>
                <div className={styles.ourPhotoWrapInner}>
                  <StaticImage
                    src="../images/us-in-sand.jpg"
                    width={1000}
                    loading="eager"
                    alt="Don and Juliane Helm"
                  />
                </div>
              </div>
              <p className={styles.largeText}>
                We love getting to know the couples and families who come to us
                and getting to thrill them with a celebration of their 'amor'
                story.
              </p>
              <h2>
                <a name="services"></a>Services We Provide
              </h2>
              <ul>
                <li>Engagement and Wedding Film Production</li>
                <li>Engagement and Wedding Photography</li>
                <li>
                  Other Lifestyle Films (newborn, first year, birthdays
                  including quinceañeras or sweet 16, family traditions,
                  whatever other ideas you have)
                </li>
                <li>
                  Other Lifestyle Photography (maternity, newborn, first year,
                  birthdays including quinceañeras or sweet 16,
                  seniors/graduation, family portraits, etc)
                </li>
                <li>Arts Films and Photography (musicians, dancers, etc)</li>
                <li>Sports Films and Photography</li>
                <li>
                  Just about anything else involving telling the stories of
                  awesome people through a high quality film and/or photos
                </li>
              </ul>
              <h2>Our Wedding Film Process and Pricing</h2>
              <p>
                This timeless cinematic work of art we craft captures all the
                joy of the fleeting moments of the day. It brings happy tears to
                the couple to help strengthen their marriage over the years as
                they remember why they fell in love. We are honored to get to
                play a big part in one of the most important days of a couple's
                life together. While our style is mostly unobtrusive, we are
                glad to direct things as much or as little as the couple wants
                so that they see their dreams for their film become a reality
                and one that they'll enjoy watching over and over as the years
                pass by.
              </p>
              <p>
                We truly want to make this experience possible for as many as we
                can, so we strive for top-notch quality while remaining
                affordable. To have an idea of our pricing since every film is
                unique, a high-quality creative highlight film delivered in
                stunning 4K, produced by two experienced and talented filmmakers
                (usually us), working with the latest professional Sony 4K
                full-frame mirrorless cameras, smooth motion camera stabilizers,
                professional lighting (used sparingly during the reception) and
                hi-def audio recording equipment for eight hours on your wedding
                day is only $1595, for a limited time. This includes 30 hours of
                post-production time we typically spend crafting your film to
                perfectly tell your love story with professional color grading,
                minimal effects, and audio mixing with high-quality licensed
                music. Think much more box office cinema rather than home video
                quality. We can work with just about any budget and typically
                provide a better value and faster standard three-week turnaround
                time than our competitors, so contact us today for more
                customized pricing, or to start planning your film with us! You
                can reserve us for your wedding with a small deposit of just
                $395 and pay the balance through an easy monthly installment
                plan.
              </p>
              <div className={styles.ctaWrap}>
                <a
                  className={styles.contactUs + " " + styles.contactUsOnWhite}
                  href="#contactUs"
                >
                  <span>Book Us Today</span>
                </a>
                <span className={styles.freeQuote}>
                  No Obligation Free Quote
                </span>
              </div>
              <h2>About Us</h2>
              <p>
                We met in Brazil while Don was on a mission trip and Juliane was
                working for the group as a translator. We stayed in touch
                through the phone after the trip and Don visited on his breaks
                from college. After four years of dating long distance, we tied
                the knot with a beautiful wedding in Brazil and the rest is
                history. Going on more than 15 years of marriage, we now have
                four wonderful children together and we're still deeply in
                'amor'.
              </p>
              <p>
                Don got his start in the creative arts at an early age when he
                was blessed to attend and be active in the Broken Arrow bands,
                one of the best band programs in the country. They helped foster
                his musical abilities to become a multiple-time jazz and concert
                all-state trumpet player. He attended college on a music
                scholarship and then transitioned into video production, an art
                form he'd also been developing from a young age and one that
                uses his creative, musical, and technical abilities. Since 2003,
                he's worked full-time in media production in one role or another
                including shooting and producing commercial videos and nearly 20
                wedding films, several assisted by Juliane. Juliane also grew up
                active in music, leading worship with her brother who is a
                talented guitar player. She's also developed her videography and
                photography skills over the years as she's worked with Don and
                regularly produces media for their family.
              </p>
              <h2>Our Vision</h2>
              <p>
                As Christians who believe in the importance of staying debt free
                as much as possible, we're now focused on making dream lifestyle
                films and photography more attainable than ever. We had to take
                a break for a season to focus on our own babies, but now that
                they are a bit older, we are gearing up and ready to produce
                work that will truly amaze you like these wonderful families
                we've served recently.
              </p>
              <h2>Latest Films</h2>
              <p>
                Some last minute projects we quickly filmed and put together
                recently.
              </p>
              <div className={styles.wrapperForMaxWidthElements}>
                <div className={styles.videosWrapper}>
                  <div className={styles.videoContainer}>
                    <iframe
                      src="https://www.youtube-nocookie.com/embed/mGe7I4Uir_k"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className={styles.comments}>
                    &ldquo;Don! This is amazing! Christophe and I are blown
                    away! Really, we have no words that express a deeper meaning
                    of 'thank you' that we desire to express. You are talented-
                    God given talent.&rdquo; - Jennifer
                  </div>
                  <div className={styles.comments}>
                    &ldquo;I'm crying!!!!!!!! How did you make a video that
                    fast?!!!!!! It was amazing!!!! Truly!!!!! Most tech people
                    are not creatives. You were gifted both sides. It's rare
                    that you see those two worlds mix!!!!!!!&rdquo; - Jessica
                  </div>
                  <div className={styles.videoContainer}>
                    <iframe
                      src="https://www.youtube.com/embed/85zQsoq6SPc?start=42"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className={styles.comments}>
                    &ldquo;Wow. Incredible. Great work. [The] Video is
                    incredible. Thank you so much Don. I can't believe it. Thank
                    you&rdquo; - Anthony
                  </div>
                  <div className={styles.videoContainer}>
                    <iframe
                      src="https://www.youtube-nocookie.com/embed/plJxiWpMy3M"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className={styles.commentCaption}>
                    This video shows you a little about our family and our home
                    in south Tomball, TX before the remodel.
                  </div>
                  <div className={styles.comments}>
                    &ldquo;Man!!!! Soooooo good !! Such a professional job!!!!
                    Outstanding man!&rdquo; - Lucas
                  </div>
                </div>
              </div>
              <h2>Older Films</h2>
              <p>
                As we said above, we took a break from serving clients for a
                season to focus more on raising our own family. Here's an
                example of a wedding we filmed before we invested in all our new
                gear. We didn't really have the best lenses or steadicam for
                this shoot. We also didn't mic the bride back then or use
                lighting in the reception, but at least it will give you an idea
                of how we craft short single-shooter wedding highlight films to
                tell the couple's 'amor' story. As you'll see below, the couple
                and family were very pleased with the film.
              </p>
              <p>
                We've filmed and produced 20 wedding films in total, so we also
                have other examples we can provide including love stories. We've
                also been studying a lot over the last year to take our craft to
                the next level of professionalism. We're excited and can't wait
                to impress more clients (see some quick last minute samples
                above)!
              </p>
              <div className={styles.wrapperForMaxWidthElements}>
                <div className={styles.videosWrapper}>
                  <div className={styles.videoContainer}>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/249011628?h=6b7477ca92"
                      frameborder="0"
                    ></iframe>
                  </div>
                  <div className={styles.comments}>
                    &ldquo;Thank you Don Helm for capturing our wedding day
                    perfectly, we love it!&rdquo; - Marissa
                  </div>
                  <div className={styles.comments}>
                    &ldquo;Amazing, wonderful, incredible! What a GREAT
                    video!!&rdquo; - Lori
                  </div>
                </div>
              </div>
              <h2>
                <a name="contactUs"></a>Please Introduce Yourself
              </h2>
              <p>
                We are here to serve you and your family, whether that's with a
                creative breathtaking wedding film or gorgeous family portraits
                (or a number of other <a href="#services">services</a> we
                provide involving cameras). And our pricing (wedding films
                starting at just $1,200 and photography sessions starting at
                just $100) along with our quality is sure to put a smile on your
                face and have you wanting to give us a big Brazilian hug.
              </p>
              <p>
                Now let's talk about you. Please tell us about you guys, your
                event or occasion, along with your hopes and any ideas for the
                project and we'll reach out within 24 hours to let you know if
                we're the right team for you. We hope we are. We'll talk with
                you soon. Um abraço!
              </p>
            </div>
          </div>
          <div className={styles.contactFormWrap}>
            <div class="hb-p-632d4aa56b7d600008b6da1a-1"></div>
            <img
              height="1"
              width="1"
              src="https://www.honeybook.com/p.png?pid=632d4aa56b7d600008b6da1a"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
